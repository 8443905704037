.footer {
    width: 100vw;
    margin-top: 70px;
    height: 40vh;
    background-color: #FF9700;
}

.footer-steps {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.f-container {
    display: flex;
    align-items: center;
    height: 80%;
}

.f-f-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
}

.f-f-container div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.f-f-container div img {
    object-fit: contain;
    max-width: 80px;
    padding: 20px;
}

.f-f-container div h1 {
    font-size: 3rem;
}

.f-f-container p {
    font-size: 1.6rem;
    font-weight: 300;
    width: 90%;
    margin: 0 auto;
}

.extra {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}

.extra h2 {
    font-size: 2rem;
    padding: 10px;
    margin: 0;
}

.extra ul {
    columns: 2;
    font-size: 1.6rem;
    column-gap: 20px;
}

.extra ul li {
    padding: 10px 0;
}

hr {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    border-color: rgba(0, 0, 0, 0.8);
}

.copyright-container {
    font-size: 1.4rem;
    font-weight: 600;
}

@media screen and (max-width: 940px) {
    .footer {
        height: auto;
    }

    .f-container {
        flex-direction: column;
        height: auto;
    }

    .f-f-container {
        width: 80%;
    }

    .f-f-container {
        width: 100%;
    }

    .extra {
        width: 100%;
        align-items: flex-start;
    }

    .extra h2 {
        margin-top: 20px;
    }
}