.booking, .cities, .storage, .offerings, .peers, .testimonial {
    width: 90%;
    margin: 0 auto;
    margin-top: 70px;
}

.booking h2, .cities h2, .storage h2, .offerings h2, .peers h2, .testimonial h2 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 8px;
}

.belowtext {
    height: 2px;
    width: 125px;
    background-color: #FF9700;
}

.steps, .cities-steps, .storage-steps, .offerings-steps, .peers-steps, .testimonial-steps {
    display: flex;
    width: 80%;
    font-size: 2rem;
    align-items: center;
    padding: 50px 0;
    margin: 0 auto;
    justify-content: center;
    column-gap: 20px;
}

.steps {
    column-gap: 100px;
}

.steps div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    justify-content: center;
    border: 2px solid #FF9700;
    padding: 50px;
    text-align: center;
}

.cities-steps div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
}

@media screen and (max-width: 940px) {
    .steps, .cities-steps, .storage-steps, .offerings-steps, .peers-steps, .testimonial-steps{
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .cities-steps div, .offerings-steps div, .testimonial-steps div, .peers-steps div, .storage-steps div, .inner-container {
        width: 100% !important;
    }

    .steps div {
        width: 130px;
        height: 130px;
    }
}

@media screen and (max-width: 400px) {
    .steps, .cities-steps, .storage-steps, .offerings-steps, .peers-steps, .testimonial-steps{
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .cities-steps div, .offerings-steps div, .testimonial-steps div, .peers-steps div, .storage-steps div, .inner-container {
        width: 100% !important;
    }

    .steps div {
        width: 130px;
        height: 130px;
    }
}

.cities-steps div img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 10px;
    padding: 10px;
    object-fit: contain;
}

.cities-steps div img:hover {
    background-color: black;
    cursor: pointer;
}

.storage-steps div {
    width: 28%;
    border-radius: 20px;
    background-color: rgba(0,0,0,0.8);
    color: white;
    padding: 20px;
}


.peers-steps {
    flex-direction: row;
}

.peers-steps div, .testimonial-steps div {
    width: 45%;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.5);
    padding: 0 20px 20px 20px;
    margin: 10px;
}

.peers-steps div h3, .testimonial-steps div h3 {
    color: #FF9700;
}

.testimonial-steps div h3 {
    width: 80%;
    text-align: left !important;
    margin: 0 auto;
    margin-top: 20px;
}

.peers-steps div p, .testimonial-steps div p {
    width: 80%;
    text-align: justify;
    margin: 0 auto;
    margin-top: 10px;
}

.container {
    display: flex;
    flex-direction: column;
}

.inner-container {
    display: flex;
}

.inner-container img {
    max-width: 150px;
    object-fit: contain;
}

.inner-container h3 {
    color: #FF9700;
}