.bm-burger-button, .bm-burger-bars, .bm-cross-button, .bm-menu-wrap, .bm-menu, .bm-item-list, .bm-overlay {
  display: none;
}
@media screen and (max-width: 600px) {
/* Position and sizing of burger button */
.bm-burger-button, .bm-burger-bars, .bm-cross-button, .bm-menu-wrap, .bm-menu, .bm-item-list, .bm-overlay {
  display: initial;
}

.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    right: 35px;
    top: 20px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: black;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    display: none;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    height: 100% !important;
    width: 100% !important;
    margin-top: 30px;
    left: 0;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background-color: rgba(255,151,0,0.8);
    left: 0;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-top: -60px;
  }
  
  /* Individual item */
  .bm-item li{
    margin: 20px 0;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    position: fixed !important;
    margin-top: 30px;
    background-color: #FF9700;
    width: 100% !important;
    left: 0;
  }

  /* Individual item */
.bm-item {
    padding: 0;
    margin: 0;
    display: inline-block;
    color: #d1d1d1;
    text-align: center;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 2.5rem;
  }
  
  .bm-item:hover {
    color: #ffffff;
  }
}