.login-start {
    background-color: white;
    color: white;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    margin-top: 70px !important;
}

.login {
    color: #FF9700;
    padding-top: 0 !important;
    padding: 100px 100px 100px 20px;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}

.login h2 {
    font-size: 2.8rem;
}

.login p {
    font-size: 1.6rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 80% !important;
    padding-right: 20px;
}

.phonein {
    width: auto !important;
    max-width: 100%;
}

.phonein input {
    margin: 5px !important;
}

#btn {
    margin-top: 20px !important;
}

#btn,
#facebook,
#google {
    background-color: #FF9700;
    border: 0;
    padding: 15px;
    border-radius: 10px;
    width: 60% !important;
    margin: 10px 0;
    margin-right: 10px;
    font-size: 2rem;
    color: white;
    font-weight: 500;
}

#facebook {
    background-color: #00B2FF;
}

#google {
    background-color: #FF0000;
}

.login-img {
    max-width: 600px;
    max-height: 600px;
    margin: auto;
}

@media screen and (max-width: 800px) {
    .login-start {
        flex-direction: column !important;
        align-items: center;
    }

    .login-img {
        max-width: 300px;
        max-height: 80%;
        margin: auto;
    }
}

@media screen and (max-width: 400px) {
    .login {
        width: 100% !important;
        padding: 30px;
    }

    .form {
        width: 100% !important;
    }

    #btn,
    #facebook,
    #google {
        width: 80% !important;
        margin-right: 10px;
    }
}

.success {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1;
}

.success h2 {
    text-align: center;
}