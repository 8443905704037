* {
  font-family: "Monserrat";
  overflow-x: hidden;
}

html {
  font-size: 10px;
}

.start {
  width: 100%;
  height: 100vh;
  background-image: url(/public/apnistorage_start.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-data {
  margin-top: 60px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  width: 90%;
  height: calc(100vh - 60px);
  font-size: 3rem;
}

.start-data-book {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header {
  height: 80px;
  width: 100%;
  background-color: #FF9700;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 1;
  font-size: 1.6rem;
  overflow: hidden;
}

.details-header {
  height: 20px;
  width: 100%;
  display: flex;
  padding-top: 2px;
}

.details-header ul {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px !important;
  margin: 0;
}

.details-header ul li a {
  text-decoration: none;
  color: black;
}

.details-header ul li {
  padding: 0 20px;
}

.App-header {
  height: 60px;
  width: 90%;
  background-color: #FF9700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  font-size: 1.6rem;
  overflow: hidden;
  margin: 0 auto;
}

.logo-div {
  display: flex;
  font-size: 1.9rem;
  color: black;
  align-items: center;
  width: 60%;
}

.App-Nav>ul {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  list-style: none;
  padding: 0 20px;
}

.App-Nav>ul>li {
  padding: 5px 15px;
  color: white;
}

.login-btn {
  background-color: white;
  border-radius: 5px;
  color: #FF9700 !important;
}

.login-btn a {
  text-decoration: none;
  color: black;
}

.App-logo {
  height: 50px;
  padding: 2px 10px 0 10px;
  object-fit: contain;
}

.book-now {
  background-color: #FF9700;
  color: white;
  border-radius: 10px;
  border: 0;
  padding: 10px;
  font-size: 2rem;
  cursor: pointer;
}

.cart {
  font-size: 2.2rem !important;
}

@media screen and (max-width: 600px) {
  .App-header {
    width: 100%;
    border-radius: 0;
  }

  .logo-div {
    width: 80% !important;
    font-size: 2.5rem;
  }

  .details-header ul li a {
    font-size: 1.4rem;
  }

  .App-Nav>ul>li {
    padding: 5px 2px;
    color: white;
    font-size: 2rem;
  }

  .App-Nav>ul {
    padding: 0 5px;
  }
}